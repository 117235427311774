import { render, staticRenderFns } from "./avalonMenuStylesEditPalette.vue?vue&type=template&id=012e0034&scoped=true&"
import script from "./avalonMenuStylesEditPalette.vue?vue&type=script&lang=js&"
export * from "./avalonMenuStylesEditPalette.vue?vue&type=script&lang=js&"
import style0 from "./avalonMenuStylesEditPalette.vue?vue&type=style&index=0&id=012e0034&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012e0034",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VColorPicker,VDivider,VHover,VSheet})
