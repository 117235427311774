<template>
  <div class="px-6">
    <h3 class="pt-3" :style="`color : ${wsDARKER}`">{{ $t('Fonts') }}</h3>

    <!-- Current fonts -->
    <v-sheet
        :style="`border : 1px solid ${wsDARKLIGHT} !important`"
        class="mt-3 mb-3 "
        style="border-radius: 8px"
    >

      <div class="pa-4 ">
        <div class="cut-text">
          <h1 :style="`color : ${wsDARKER};font-family : ${ CONFIG_PARAM('fonts','headings','family')}`">
            {{ CONFIG_PARAM('fonts','headings','family') }}
          </h1>
        </div>

        <h4 :style="`color : ${wsDARKER}`" style="font-size: 14px" class="font-weight-regular mt-1">Це приклад тексту, який використовується для перегляду</h4>
      </div>

     <v-divider  :style="`border-color : ${wsDARKLIGHT}; height : 1px`" />
      <v-btn
          @click="openFontsLibrary"
          block text
          :color="wsACCENT"
          class="noCaps py-5"
      >
        {{ $t('ChooseFonts') }}
      </v-btn>
    </v-sheet>

    <h4 class="pt-3" :style="`color : ${wsDARKER}`">{{ $t('avalon.config.fonts.settings') }}</h4>

    <!-- Font type selector -->
    <v-hover
        #default="{hover}"
        v-for="(element) in fontElements" :key="element"
    >
      <!-- Font type  -->
      <v-sheet
          @click="openEditFontElement(element)"
          :style="`border : ${hover ? 2 : 1}px solid ${wsDARKLIGHT} !important`"
          class="mt-2 d-flex justify-space-between align-center pointer"
          style="border-radius: 8px; padding : 6px 15px"
          max-height="48px"
      >

        <h4 :style="`color : ${ wsDARKER}; font-size : ${element !=='headings' ? 13 : 16}px`" class="py-2" >
          {{ $t('avalon.config.fonts.elements.' + element ) }}
        </h4>

        <v-icon
            v-if="hover"
            :color="wsACCENT">
          mdi-pencil
        </v-icon>

      </v-sheet>
    </v-hover>

  </div>


</template>

<script>
export default {
  name: "avalonMenuStyles",
  data() {
    return {
      fontElements : ['headings', 'paragraphs', 'other']
    }
  },
  methods : {
    openFontsLibrary() {
      this.$store.state.avalon.menuNavigation = 'fonts_library'
      this.$store.state.avalon.menuNavigationReturn = 'fonts'
    },
    openEditFontElement(fontElement) {
      this.$store.state.avalon.menuNavigation = 'fonts_edit_element'
      this.$store.state.avalon.menuNavigationReturn = 'fonts'
      this.$store.state.avalon.menuNavigationElement = fontElement
    },
  },
  beforeMount() {
    this.CHECK_CONFIG_PARAM('fonts')
    this.CHECK_CONFIG_PARAM('fonts','families')
    this.CHECK_CONFIG_PARAM('fonts','headings')
    this.CHECK_CONFIG_PARAM('fonts','headings','family')

    this.$store.state.avalon.initialConfigData = this.COPY(this.SITE_CONFIG)
    this.$store.state.avalon.menuNavigationReturn = 'settings'
  }

}
</script>

<style scoped>
.roundedTopLeft  {
  border-top-left-radius : 7px;
}
.roundedTopRight  {
  border-top-right-radius : 7px;
}
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>