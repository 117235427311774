<template>
  <v-row class="no-gutters flex-wrap flex-column fill-height " >

    <!-- Main items -->
    <v-col cols="auto" class="grow overflow-y-auto overflow-x-hidden py-0" style="height: 10px"  >


      <avalon-menu-pages                v-if="$store.state.avalon.menuNavigation === 'pages'"/>
      <avalon-menu-settings             v-if="['settings' , 'settings_general'].includes($store.state.avalon.menuNavigation)"/>
      <avalon-menu-styles               v-if="$store.state.avalon.menuNavigation === 'styles'"/>
      <avalon-menu-styles-edit-palette  v-if="$store.state.avalon.menuNavigation === 'styles_palette'" />
      <avalon-menu-styles-edit-theme    v-if="$store.state.avalon.menuNavigation === 'styles_theme'" />
      <avalon-menu-fonts                v-if="$store.state.avalon.menuNavigation === 'fonts'" />
      <avalon-menu-fonts-library        v-if="$store.state.avalon.menuNavigation === 'fonts_library'"  />
      <avalon-menu-fonts-edit-element   v-if="['fonts_edit_element' , 'fonts_edit_element_variable'].includes($store.state.avalon.menuNavigation)"  />
      <avalon-menu-news v-if="$store.state.avalon.menuNavigation === 'news'"/>

    </v-col>

    <!-- Bottom items -->
    <v-col
        v-if="![ 'settings' , 'styles' , 'fonts' ].includes($store.state.avalon.menuNavigation)"
        cols="auto" class="shrink pt-3"  >
      <v-divider
          v-if="!['fonts_edit_element' ].includes($store.state.avalon.menuNavigation)"
          class="mb-3" :style="`background-color: ${wsLIGHTCARD}`" />
      <ws-avalon-navigation-list
          v-if="!['styles_palette','styles_theme','fonts_library','fonts_edit_element','fonts_edit_element_variable'].includes($store.state.avalon.menuNavigation)"
          v-model="selectedItem"
          :items="items_bottom"
          no-edit
      />
      <!-- Save Changes buttons  -->
      <div v-else-if="!['fonts_edit_element_variable'].includes($store.state.avalon.menuNavigation)" class="px-6 pb-2">

        <ws-button
            @click="saveConfig"
            label="SaveSettings"
            block
            :disabled="!$store.state.avalon.configChanged"
        />
        <ws-button
            @click="resetConfigChanges"
            label="ResetSettings"
            text
            block
            :disabled="!$store.state.avalon.configChanged"
        />

      </div>
    </v-col>


  </v-row>
</template>

<script>

import {mapState, mapActions, mapMutations} from 'vuex';

import avalonMenuPages from "@/components/AvalonEditor/UI/sidebar/avalonMenuPages";
import avalonMenuSettings from "@/components/AvalonEditor/UI/sidebar/avalonMenuSettings";
import avalonMenuStyles from "@/components/AvalonEditor/UI/sidebar/style/avalonMenuStyles";
import avalonMenuStylesEditPalette from "@/components/AvalonEditor/UI/sidebar/style/avalonMenuStylesEditPalette";
import avalonMenuStylesEditTheme from "@/components/AvalonEditor/UI/sidebar/style/avalonMenuStylesEditTheme";
import avalonMenuFonts from "@/components/AvalonEditor/UI/sidebar/fonts/avalonMenuFonts";
import avalonMenuFontsLibrary from "@/components/AvalonEditor/UI/sidebar/fonts/avalonMenuFontsLibrary";
import avalonMenuFontsEditElement from "@/components/AvalonEditor/UI/sidebar/fonts/avalonMenuFontsEditElement";
import avalonMenuNews from "@/components/AvalonEditor/UI/sidebar/avalonMenuNews";

export default {
  name: "menuElementsBusiness",
  components : {
    wsAvalonNavigationList : () => import("@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList"),
    avalonMenuPages             ,
    avalonMenuSettings          ,
    avalonMenuStyles            ,
    avalonMenuStylesEditPalette ,
    avalonMenuStylesEditTheme   ,
    avalonMenuFonts             ,
    avalonMenuFontsLibrary      ,
    avalonMenuFontsEditElement  ,
    avalonMenuNews              ,

  },
  data() {
    return {
      selectedItem: 0,
      businessManager : false,
      parseFromRoute : '',
      displayNavigation : true

    }
  },
  computed: {

    ...mapState('dashboard', [ 'pageNavigation']),
    ...mapState('business', ['selectedBusiness']),
    ...mapState('avalon',['sideMenu' , 'sidemenuTrigger' , 'menuNavigation']),
    items_bottom() {
      return [
        {
          text: this.$t('Settings'),
          icon: 'mdi-cog',
          action  : () => {
            this.$store.state.avalon.menuNavigation = 'settings'
            this.$store.state.avalon.menuNavigationReturn = 'pages'
          },
          id : 'settings',
          value : 'editor/settings',
        },
        { text: this.$t('Logout'),
          icon: 'mdi-logout-variant',
          action:  this.SHOW_MODAL_CONFIRM_LOGOUT,
          value : 'logout'
        }
      ]
    },
    pageNavigationSelect() {
      if ( this.pageNavigation.length === 0 ) {
        return []
      }
      let items = this.pageNavigation
      items.unshift({
        text : this.$t('Back'),
        path : this.businessPublicLink(this.$store.state.dashboard.pageNavigationReturnRoute)
      })

      return items
    }

  },
  watch : {
    pageNavigation(value) {
      this.selectedItem = value
    },
    sidemenuTrigger() {
      this.displayNavigation = false
      setTimeout(() => {
        this.displayNavigation = true
      },1)
    },
    menuNavigation(value) {
      if ( ['pages'].includes(value) ) {
        this.$store.state.avalon.selectedNews = null
      }
    }
  },
  methods: {
    ...mapActions('avalon' , ['EDIT_SITE_CONFIG']),
    ...mapMutations('dashboard', ['SHOW_MODAL_CONFIRM_LOGOUT']),

    async saveConfig() {
      let result  = await this.EDIT_SITE_CONFIG({config : this.SITE_CONFIG})
      if ( !result ) {
        this.notify(this.$t('NetworkError') , 'error')
      }
      this.$store.state.avalon.configChanged = false
      this.notify(this.$t('ChangesSaved') , 'success')

    },
    resetConfigChanges() {
      this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.initialConfigData)
      this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.config)
      this.$store.state.avalon.configChanged = false
    },
    select(item) {

      if ( this.SM && item.messenger ) {
        this.$store.state.messenger.displayMessenger = true
        this.$store.state.dashboard.sideMenuMobile = false
        return
      }

      switch (item.action.type) {

        case 'route':
          this.SET_PAGE_TITLE(item.text);
          this.$router.push(this.businessDashLink(item.action.path))
          break;

      }

      if ( this.SM) {
        this.$store.state.dashboard.sideMenuMobile = false
      }

    },

    //technical
    isSelected(value) {
      return this.selectedItem === value
    },
    checkRoute() {

      if ( this.$store.state.dashboard.displayPageNavigation ) {
        return
      }

      // this.authData.forEach(section => {
      //   let currentRoute = section.items.find( el => el.path && this.$route.path === this.businessDashLink(el.path )  )
      //   if ( currentRoute ) {
      //     this.selectedItem = currentRoute.path
      //   }
      // });

    }
  },
  beforeMount() {
    this.$store.state.avalon.menuNavigation = 'pages'
  },
  created() {

    let route = this.$route.path.replace(this.businessDashLink(''),'')
    this.selectedItem = route ? route : 0
    this.checkRoute()
  }
}
</script>

<style lang="scss" scoped>

::-webkit-scrollbar{height:16px;overflow:visible;width:10px}
::-webkit-scrollbar-button{height:0;width:0}
::-webkit-scrollbar-track{background-clip:padding-box;box-shadow:inset 0px 0 0 #e6e6e6}
::-webkit-scrollbar-track:hover{background-color:transparent;}
::-webkit-scrollbar-track:active{background-color:transparent; cursor: all-scroll}
::-webkit-scrollbar-thumb{background-color:#94b3c7;min-height:28px;padding:100px 0 0;}
::-webkit-scrollbar-thumb:hover{background-color:#567186;}
::-webkit-scrollbar-thumb:active{background-color:#567186;}
</style>