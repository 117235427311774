<template>

  <div class="fill-height">

    <v-hover #default="{hover}">
      <v-sheet
          :color="!hover ? 'transparent' : (wsACCENT + '22')"
          v-if="!$store.state.avalon.pageSearch"
          @click="$store.commit('avalonPage/OPEN_NEW_PAGE') "
          style="padding-left : 27px; transition: background-color 0.2s ease"

      >
        <ft-select
            :items="addItemSelect"
            :icon-color="wsATTENTION"
        >
          <div class="py-2 d-flex align-center  pointer">
            <v-icon style="margin-right: 15px" :color="wsACCENT">mdi-plus-circle</v-icon>
            <h5 :style="`color : ${wsACCENT}`">{{ $t('Create') }}</h5>
          </div>
        </ft-select>
      </v-sheet>
    </v-hover>


    <ws-avalon-navigation-list
        v-if="$store.state.avalon.pageSearch"
        :items="itemsSearch"
        mandatory
    />
    <div v-if="$store.state.avalon.pageSearch && itemsSearch.length === 0"
         class="fill-height d-flex align-center justify-center"
    >
      <div>
        <h3 class="text-center" :style="`color : ${wsACCENT}`">{{ $t('NothingFound') }}</h3>
        <h5 class="text-center mt-2" :style="`color : ${wsACCENT}`">{{ $t('NoPagesFoundText') }}</h5>
        <div class="d-flex justify-center">
          <ws-button
              @click="$store.state.avalon.pageSearch = ''"
              :color="wsACCENT"
              label="ResetSearch"
              class="mt-5"
              outlined
          />
        </div>

      </div>

    </div>



    <ws-avalon-navigation-list
        v-model="$store.state.avalon.selectedPage"
        v-if="!$store.state.avalon.pageSearch"
        :items="items"
        mandatory
    />

    <!-- Edit Folder-->
    <ws-dialog
        v-if="displayFolderDialog"
        v-model="displayFolderDialog"
        :title="!$store.state.avalon.displayEditFolderDialog ? $t('AddFolder') : $t('EditFolder')"
        portal
        @save="addEditFolder"
        @close="$store.state.avalon.displayEditFolderDialog = false"
    >
      <ws-text-field
          v-model="folderEntityData.name"
          :label="$t('Name')"
          :placeholder="$t('EnterName')"
      />
    </ws-dialog>

    <!-- Delete Folder-->
    <ws-dialog
        :title="$t('DeleteFolder')"
        v-model="$store.state.avalon.displayDeleteFolderDialog"
        @delete="deleteFolder"
        display-confirm-delete
        close-delete
    >
      <template #confirm-delete >
        <h5 >{{ $t('DeleteFolderConfirmation') }}</h5>
        <ws-switch
            class="mt-3 mb-n3"
            v-model="folderEntityData.delete_content"
            :placeholder="$t('DeleteFolderPages')"
        />
      </template>


    </ws-dialog>



  </div>

</template>

<script>
import wsAvalonNavigationList from "@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList";
import {mapActions, mapState} from "vuex";
export default {
  name: "avalonMenuPages",
  components : {
    wsAvalonNavigationList
  },
  data() {
    return {
      displayFolderDialog : false,
      folderEntityData : {}
    }
  },
  computed : {
    ...mapState('avalon' , ['displayEditFolderDialog' , 'displayDeleteFolderDialog' ]),

    addItemSelect() {
      return [
        { text : this.$t('AddPage') , icon : 'mdi-file-outline', action : () => this.$store.commit('avalonPage/OPEN_NEW_PAGE') },
        { text : this.$t('AddFolder') , icon : 'mdi-folder' , action : () => this.openAddFolder() },
        { text : this.$t('AddNews') , icon : 'mdi-newspaper-variant-outline' , action : () => this.$store.commit('avalonPage/OPEN_NEW_NEWS') }
      ]
    },
    itemsSearch() {
      let items = []
      let pages = this.CURRENT_LANG_PAGES.filter( el => el.text.toLowerCase().includes(this.$store.state.avalon.pageSearch.toLowerCase().trim())  ) || []
      let courses = this.$store.state.avalon.coursesSelect.filter( el => !!el.page[this.$store.state.avalon.selectedLang] && el.text.toLowerCase().includes(this.$store.state.avalon.pageSearch.toLowerCase().trim())  )

      pages.forEach(page => {
        items.push(
            {
              text: page.text,
              value : page.value,
              icon: !page.noLang ? 'mdi-file' : 'mdi-file-outline',
              path: this.businessPublicLink(`editor/page/${page.value}`),
              edit : false,
              page_id : page.value,
            },
        )
      })

      courses.forEach(course => {
        items.push(
            {
              text: course.text,
              value : course.value,
              icon: 'mdi-file',
              edit : false,
              is_course : true,
              path: this.businessPublicLink(`editor/page/${course.page[this.$store.state.avalon.selectedLang]}`),
              page_id : course.page[this.$store.state.avalon.selectedLang] || null,
            },
        )
      })

      return items

    },
    items() {

      let courses = []
      this.$store.state.avalon.coursesSelect.forEach(course => {
        courses.push(
            {
              text: course.text,
              value : course.value,
              page_id : course.page[this.$store.state.avalon.selectedLang] || null,
              no_edit : !course.page[this.$store.state.avalon.selectedLang],
              is_course : true,
              path: this.businessPublicLink(`page/${course.page[this.$store.state.avalon.selectedLang] || (course.value +'/course')}/editor`),
            },
        )
      })

      let items = [

        {
          text: this.$t('News'),
          value : 'news',
          icon: 'mdi-newspaper-variant-outline',
          action : () => { this.$store.state.avalon.menuNavigation = 'news' ; this.$store.state.avalon.menuNavigationReturn = 'pages'  },
          no_edit : true
        },
        {
          text: this.$t('HomePage'),
          value : (this.CURRENT_LANG_HOMEPAGE ?  this.CURRENT_LANG_HOMEPAGE.value : 'homepage').toString(),
          icon: 'mdi-home',
          path: this.businessPublicLink(this.CURRENT_LANG_HOMEPAGE ? `page/${this.CURRENT_LANG_HOMEPAGE.value}/editor` : 'blank/homepage/editor'  ),
          edit : false,
          page_id : this.CURRENT_LANG_HOMEPAGE ? this.CURRENT_LANG_HOMEPAGE.value : null,
          is_published : !!this.CURRENT_LANG_HOMEPAGE
        },
        {
          text: this.$t('Courses'),
          value : 'courses',
          icon: 'mdi-school-outline',
          action: 'courses',
          children : courses
        },
      ]

      this.$store.state.avalon.folders.forEach(folder => {
        let children = []
        this.CURRENT_LANG_PAGES.filter( el=> el.folder === folder.value ).forEach(child => {
          children.push( {
            text: child.text,
            value : child.value.toString(),
            path: this.businessPublicLink(`page/${child.value}/editor`),
            edit : false,
            page_id : child.value,
            alias : child.alias,
            is_published : child.is_published,
            folder : folder.value
          })
        })
        items.push({
              text: folder.text,
              value : folder.value,
              icon: 'mdi-folder-outline',
              edit : false,
              expanded : false,
              is_folder : true,
              children : children
            }
        )
      })

      this.CURRENT_LANG_PAGES.forEach(page => {
        if ( !page.folder ) {
          items.push(
              {
                text: page.text,
                value : page.value.toString(),
                icon: !page.noLang ? 'mdi-file' : 'mdi-file-outline',
                path: this.businessPublicLink(`page/${page.value}/editor`),
                edit : false,
                page_id : page.value,
                alias : page.alias,
                is_published : page.is_published
              }
          )
        }

      })

      // items.push( {
      //   text: this.$t('Page404'),
      //   value : '404',
      //   icon: 'mdi-file',
      //   path: this.businessPublicLink(`editor/page/404`),
      // })


      return items

    },
  },
  watch : {
    displayEditFolderDialog(value) {
      if ( value ) {
        this.openEditFolder()
      }
    },
    displayDeleteFolderDialog() {
      this.openDeleteFolder()
    },
  },
  methods : {
    ...mapActions('avalon' , [
        'ADD_EDIT_FOLDER',
        'DELETE_FOLDER'
    ]),
    async deleteFolder() {
      let result = await this.DELETE_FOLDER(this.folderEntityData)

      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }

      this.$store.state.avalon.displayDeleteFolderDialog = false
    },
    async addEditFolder() {

      let result = await this.ADD_EDIT_FOLDER(this.folderEntityData)

      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }

      this.displayFolderDialog = false
      this.$store.state.avalon.displayEditFolderDialog = false

    },
    openAddFolder() {
      this.folderEntityData = {}
      this.displayFolderDialog = true
    },
    openEditFolder() {
      this.folderEntityData = {
        id : this.$store.state.avalon.selectedFolder.value,
        name : this.$store.state.avalon.selectedFolder.text
      }
      this.displayFolderDialog = true
    },
    openDeleteFolder() {
      this.folderEntityData = {
        id : this.$store.state.avalon.selectedFolder.value,
        name : this.$store.state.avalon.selectedFolder.text,
        delete_content : false
      }
    }
  }
}
</script>

<style scoped>

</style>