<template>
   <v-sheet height="100vh" color="transparent"  >

       <v-row class="no-gutters flex-wrap flex-column fill-height " >

         <!-- Header -->
         <v-col cols="auto" class="shrink"  >

           <!-- WeStudy Logo, Search and Return button -->
           <v-sheet
               class="d-flex  justify-space-between align-center"
               color="transparent"
               height="60"
               v-if="!$store.state.avalon.displaySearch || $store.state.avalon.menuNavigation !== 'pages'"
           >

             <!-- Logo and search -->
             <template v-if="$store.state.avalon.menuNavigation === 'pages' ">
               <router-link :to="`/${ $store.state.domain ? '' : $store.state.business.selectedBusiness.alias}`" target="_blank">
                 <img id="dashboard_logo_avalon"  height="25px" class="mt-3 pl-6" alt="WeStudy" src="/library/img/westudy_logo.png" />
               </router-link>

               <v-btn :color="wsACCENT"
                      :class="[{'mr-5' : sideMenu}]"
                      @click="$store.state.avalon.displaySearch = true "  icon
               >
                 <v-icon  >mdi-magnify</v-icon>
               </v-btn>
             </template>
             <!-- Return button  -->
             <template v-else>
               <v-btn
                   @click="returnBack"
                   :color="wsACCENT"
                   block text
                   class="noCaps justify-start pl-6"

                   style="font-size : 13px; margin-top: -19px"
                   height="40"
               >
                 <v-icon class="mr-3">
                   mdi-{{$store.state.avalon.menuOnlyStylesAndFonts && $store.state.avalon.menuNavigation === 'settings' ? 'close' : 'arrow-left' }}
                 </v-icon>
                 {{ $t($store.state.avalon.menuOnlyStylesAndFonts && $store.state.avalon.menuNavigation === 'settings' ? 'Close' : 'Back') }}

               </v-btn>
             </template>



           </v-sheet>
           <!-- Search Field -->
           <v-sheet
               v-else
               class=""
               color="transparent"
               height="50"

           >
             <div class="d-flex pl-2 fill-height justify-space-between align-center">
               <ws-text-field
                   v-model="$store.state.avalon.pageSearch"
                   :placeholder="$t('EnterPageName')"
                   solo flat
                   background-color="transparent"
               />

               <v-btn :color="wsACCENT"
                      :class="[{'mr-5' : sideMenu}]"
                      @click="$store.state.avalon.displaySearch = false; $store.state.avalon.pageSearch = ''"  icon
               >
                 <v-icon>mdi-close</v-icon >
               </v-btn>
             </div>
           </v-sheet>
           <!-- Search Field -->
           <v-divider
               v-if="$store.state.avalon.displaySearch && $store.state.avalon.menuNavigation === 'pages'"
               :style="`border-color : ${wsDARKLIGHT}`"
               style="padding-bottom: 9px"
           />

           <v-btn
               v-if="$store.state.avalon.menuNavigation === 'pages'"
               :to="businessDashLink('')"
               :color="wsACCENT"
               block text
               class="noCaps justify-start pl-6 mb-4"
               style="font-size : 13px"
               height="40"
           >
             <v-icon style="margin-right: 18px">mdi-arrow-left</v-icon>
             {{ $t('BackToAdminPage') }}
           </v-btn>



         </v-col>
         <!-- Menu Items -->
         <v-col cols="auto" class="grow overflow-y-auto overflow-x-hidden" style="height: 100px"  >
           <avalon-menu />
         </v-col>

       </v-row>

     <ws-dialog
         v-model="displayConfirmReturn"
         :title="$t('avalon.config.confirm_return.title')"
         @save="saveChangesAndReturn"
         @cancel="cancelChangesAndReturn"
         cancel-action
         :save-text="$t('avalon.config.confirm_return.save')"
         :cancel-text="$t('avalon.config.confirm_return.cancel')"
     >

         <h4 class="text-center mt-16" :style="`color : ${wsACCENT}`">
           {{ $t('avalon.config.confirm_return.text') }}
         </h4>



     </ws-dialog>

  </v-sheet>
</template>

<script>
import {mapActions, mapState} from 'vuex';


import avalonMenu from "@/components/AvalonEditor/UI/sidebar/avalonMenu";
export default {
  name: "sideBarBusiness",
  components : {
    avalonMenu
  },
  data: () => ({
    selectedItem: 1,
    displayConfirmReturn : false,
    items: [
      { text: 'Real-Time', icon: 'mdi-clock' },
      { text: 'Audience', icon: 'mdi-account' },
      { text: 'Conversions', icon: 'mdi-flag' },
    ],
  }),
  computed : {
    ... mapState('avalon', ['sideMenu'] ),

    displayCondition() {
      if ( this.displayShow(['sm'])) {
       return this.sideMenu
      }
      return true
    },
    
    menuStyleClass() {
      if ( this.sideMenu ) {
        return 'menuActive'
      }
      return ''
    }
  },
  methods : {
    ...mapActions('avalon' , ['EDIT_SITE_CONFIG']),

    async saveConfig() {
      let result  = await this.EDIT_SITE_CONFIG({config : this.SITE_CONFIG})
      if ( !result ) {
        this.notify(this.$t('NetworkError') , 'error')
      }
      this.$store.state.avalon.configChanged = false
      this.notify(this.$t('ChangesSaved') , 'success')

    },

    returnBack() {
      if (this.$store.state.avalon.menuOnlyStylesAndFonts && this.$store.state.avalon.menuNavigation === 'settings') {
        this.$store.state.avalon.menuNavigation = 'pages'
        this.$store.state.avalon.sideMenu = false
        this.$store.state.avalon.menuOnlyStylesAndFonts = false

        this.$store.state.avalon.selectedElementDesign = null
        this.$store.state.avalon.selectedElementType = null
        this.$store.state.avalon.selectedElementStyle = null
        this.$store.state.avalon.selectedBlockStyle = null
        this.$store.state.avalon.selectedBlockType = null
        this.$store.state.avalon.selectedBlockDesign = null

      }
      else if ( !this.$store.state.avalon.configChanged || (['fonts_edit_element_variable'].includes(this.$store.state.avalon.menuNavigation)) ) {
        this.$store.state.avalon.menuNavigation = this.$store.state.avalon.menuNavigationReturn
      } else {
        this.displayConfirmReturn = true
      }
    },
    saveChangesAndReturn() {
      this.saveConfig()
      this.$store.state.avalon.menuNavigation = this.$store.state.avalon.menuNavigationReturn
      this.displayConfirmReturn = false
    },
    cancelChangesAndReturn() {
      this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.initialConfigData)
      this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.config)
      this.$store.state.avalon.configChanged = false
      this.$store.state.avalon.menuNavigation = this.$store.state.avalon.menuNavigationReturn
      this.displayConfirmReturn = false
    }
  }


}
</script>

<style scoped>

.menu_header {
  height: 70px;
}

.menu {

  width:60px;
  height:100%;
  background-color: white;
  color: #2c3e50;
  float:left;
  transition: all 0.3s ease-out;
  overflow-y: auto;
}
.menuActiveMobile {
  position: fixed;
  display: block;
  z-index: 999999;
  width: 100%;
  transition: all 0.3s ease-out;
}
.menuActive {
  width:280px !important;
  transition: all 0.3s ease-out;
}
.menuActive li {
  padding-left: 35px !important;
}

.menu ul {
  padding:0;
}
.menu li {
  list-style: none;
  margin: 0px;
  padding:5px;
  padding-left:17px;
  transition: all 0.3s ease-out;
}
.menu li a {
  color: #2c3e50;
  text-decoration: none;
}
.menu a:hover {
  transition: all 0.3s ease-out;
  color: antiquewhite;
}
.menu li:hover {
  background-color: #ffffff33;
}
</style>