var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-4"},[_c('h3',{staticClass:"pt-3",style:(("color : " + _vm.wsDARKER))},[_vm._v(_vm._s(_vm.$t('EditColorTheme')))]),_c('v-sheet',{staticClass:"mt-2 mb- d-flex justify-space-between align-center pointer",staticStyle:{"border-radius":"8px","padding":"6px 15px"},style:(("border : 1px solid " + _vm.wsDARKLIGHT + " !important"))},[_c('v-btn',{attrs:{"disabled":!_vm.previousPalette,"color":_vm.wsACCENT,"icon":""},on:{"click":_vm.openPreviousPalette}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('h5',{style:(("color : " + _vm.wsDARKER))},[_vm._v(" "+_vm._s(_vm.$t('avalon.color_styles.' + _vm.menuNavigationStyleTheme ))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.nextPalette,"color":_vm.wsACCENT,"icon":""},on:{"click":_vm.openNextPalette}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1),(_vm.$store.state.avalon.selectedBlockType || _vm.$store.state.avalon.selectedElementType )?_c('div',{staticClass:"pt-5 pb-2"},[_c('h5',{staticClass:"pointer hoverUnderline",style:(("color : " + _vm.wsACCENT)),on:{"click":_vm.returnToAllVariables}},[_vm._v(_vm._s(_vm.$t('DisplayAllParameters')))])]):_vm._e()],1),_vm._l((_vm.paramsSets),function(paramsSet,index){return _c('div',{key:index},[_c('h4',{staticClass:"pt-3 px-4",style:(("color : " + _vm.wsDARKER))},[_vm._v(" "+_vm._s(_vm.$t(_vm.GET_COLORS_PARAMS_LOCALES(paramsSet.block)))+" ")]),_vm._l((paramsSet.elements),function(element){return _c('div',{key:paramsSet + element,staticClass:" pointer"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"d-flex justify-space-between align-center px-4 py-2",attrs:{"color":"transparent"},on:{"click":function($event){return _vm.selectElement(element)}}},[_c('h5',{style:(("color : " + _vm.wsDARKER))},[_vm._v(" "+_vm._s(_vm.$t(_vm.GET_COLORS_PARAMS_LOCALES(paramsSet.block , element)))+" ")]),_c('v-sheet',{staticStyle:{"border-radius":"50%"},style:(("border : " + (element === _vm.expandElement || hover ? 2 : 1) + "px solid " + (_vm.CONFIG_PARAM_COLOR('styles',_vm.menuNavigationStyleTheme , element ) ? _vm.wsACCENT : 'red'))),attrs:{"height":"22","width":"22","color":_vm.CONFIG_PARAM_COLOR('styles',_vm.menuNavigationStyleTheme , element )}},[_c('span')])],1)]}}],null,true)}),_c('v-expand-transition',[(element === _vm.expandElement)?_c('div',{staticClass:"pt-3 mx-3"},[_c('v-sheet',{staticClass:"pa-3",staticStyle:{"border-radius":"8px"},attrs:{"color":_vm.wsLIGHTCARD}},[_c('h4',{style:(("color : " + _vm.wsDARKER))},[_vm._v(" "+_vm._s(_vm.$t('avalon.color_styles.choose_another_color'))+" ")]),_c('v-sheet',{staticClass:"mt-3 mb-3",staticStyle:{"border-radius":"8px"},attrs:{"color":"transparent"}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.AVALON_COLORS_LIST),function(color,index){return _c('v-hover',{key:color,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"pointer",class:[
                      {roundedTopLeft : index === 0} ,
                      {roundedBottomLeft : index === 0} ,
                      {roundedTopRight : index === 5},
                      {roundedBottomRight : index === 5}
                    ],staticStyle:{"position":"relative"},attrs:{"color":_vm.CONFIG_PARAM_COLOR('colors', color),"width":"100%","height":"32"},on:{"click":function($event){return _vm.changeElementColor(element , color )}}},[_c('div',{class:[
                      {roundedTopLeft : index === 0} ,
                      {roundedBottomLeft : index === 0} ,
                      {roundedTopRight : index === 5},
                      {roundedBottomRight : index === 5}
                    ],staticStyle:{"width":"100%","height":"100%"},style:(hover || _vm.$store.state.avalon.config.styles[_vm.menuNavigationStyleTheme][element] === color ? ("border : 2px solid " + _vm.wsDARKLIGHT + ";") : null)})])]}}],null,true)})}),1)]),_c('h5',{staticClass:"ml-n2",style:(("color : " + _vm.wsDARKER)),on:{"click":function($event){_vm.expandColorPicker = !_vm.expandColorPicker}}},[_c('v-icon',{attrs:{"color":_vm.wsDARKER}},[_vm._v("mdi-menu-"+_vm._s(!_vm.expandColorPicker ? 'right' : 'down'))]),_vm._v(" "+_vm._s(_vm.$t('ChooseColor' ))+" ")],1),_c('v-expand-transition',[(_vm.expandColorPicker)?_c('div',[_c('ws-text-field',{staticClass:"mt-4",attrs:{"value":_vm.GET_AVALON_COLOR(_vm.$store.state.avalon.config.styles[_vm.menuNavigationStyleTheme][element]),"background-color":"white"},on:{"change":function($event){_vm.$store.state.avalon.configChanged = true; _vm.$store.state.avalon.config.styles[_vm.menuNavigationStyleTheme][element] = $event}}}),_c('v-color-picker',{staticClass:"mt-3",staticStyle:{"background-color":"transparent"},attrs:{"value":_vm.GET_AVALON_COLOR(_vm.$store.state.avalon.config.styles[_vm.menuNavigationStyleTheme][element]),"hide-inputs":"","hide-mode-switch":""},on:{"input":function($event){_vm.$store.state.avalon.configChanged = true; _vm.$store.state.avalon.config.styles[_vm.menuNavigationStyleTheme][element] = $event}}})],1):_vm._e()])],1)],1):_vm._e()])],1)}),_c('v-divider',{staticClass:"mt-3",style:(("border-color : " + _vm.wsDARKLIGHT))})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }