<template>
  <div>
    <h3 class="pt-3 mb-3" :style="`color : ${wsDARKER}`">
      {{ $t(`avalon.config.fonts.edit_paragraphs`) }}
    </h3>

    <font-family-select
        @input="checkConfigChanged(true)"
        v-model="$store.state.avalon.config.fonts.paragraphs.family"
    />
    <font-param-select
        @input="checkConfigChanged"
        v-model="$store.state.avalon.config.fonts.paragraphs.weight"
        :family="$store.state.avalon.config.fonts.paragraphs.family"
    />
    <font-param-select
        @input="checkConfigChanged"
        v-model="$store.state.avalon.config.fonts.paragraphs.style"
        font-style
    />

    <a-parameter-slider
        @input="checkConfigChanged"
        v-model="$store.state.avalon.config.fonts.paragraphs.lineHeight"
        style="width: 100%"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="avalon.config.fonts.line_height"
        min="90"
        max="300"
        unit="%"
        light
    />

    <a-parameter-slider
        @input="checkConfigChanged"
        v-model="$store.state.avalon.config.fonts.paragraphs.spacing"
        style="width: 100%"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="avalon.config.fonts.letter_spacing"
        min="0"
        max="50"
        light
    />

    <font-param-select
        @input="checkConfigChanged"
        v-model="$store.state.avalon.config.fonts.paragraphs.case"
        font-case
    />

    <div v-for="(screenSize , i) in ['','_sm']" :key="i">
      <h4 class="pt-7 pb-2" :style="`color : ${wsDARKER}`">
        {{ $t(`Sizes` + screenSize) }}
      </h4>
      <a-parameter-slider
          v-for="size in ['p','h6']" :key="i + size"
          @input="checkConfigChanged"
          v-model="$store.state.avalon.config.fonts.paragraphs.size[size + screenSize]"
          style="width: 100%"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          :label="`avalon.color_styles.${size}`"
          min="10"
          max="100"
          light
      />
    </div>

  </div>
</template>

<script>
import fontFamilySelect from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontFamilySelect";
import fontParamSelect from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontParamSelect";
export default {
  name: "fontsSettingsHeading",
  components : {
    fontFamilySelect,
    fontParamSelect
  },
  methods : {
    checkConfigChanged(checkFonts) {
      this.$store.state.avalon.configChanged = true
      if (checkFonts) {
        this.CHECK_CONFIG_FONTS()
      }
    }
  },
  beforeMount() {
    this.CHECK_CONFIG_PARAM('fonts','families')
    this.CHECK_CONFIG_PARAM('fonts','paragraphs')
    this.$store.state.avalon.initialConfigData = this.COPY(this.SITE_CONFIG)
  }
}
</script>

<style scoped>

</style>