<template>
  <div>
    <h3 class="pt-3 mb-3" :style="`color : ${wsDARKER}`">
      {{ !isEditing
        ? $t(`avalon.config.fonts.edit_other`)
        : $t(`font.elements.edit.${selectedElement}.${selectedVariable}`) }}
    </h3>

    <div v-if="!isEditing && ($store.state.avalon.selectedBlockType || $store.state.avalon.selectedElementType)" class="mt-5 pb-2">

      <h5 @click="returnToAllVariables" class="pointer hoverUnderline" :style="`color : ${wsACCENT}`">{{ $t('DisplayAllParameters') }}</h5>

    </div>

    <!-- Elements and variables-->
    <v-slide-transition mode="out-in">
      <div v-if="!isEditing" key="elements">
        <template v-for="(variables,element, index) in fontElementsFiltered" >

          <!-- Element-->
          <h4 :key="element + 'title'"
              :style="`color : ${wsDARKER}`"
              :class="[{'mt-4' : index > 0 }]"
              class="pt-3 mb-3"
          >
            {{ GET_FONT_PARAM_NAME(element) }}
          </h4>

          <template v-for="( params , variable) in variables">
            <font-param-select
                v-if="$store.state.avalon.config.fonts.elements && $store.state.avalon.config.fonts.elements[element] && $store.state.avalon.config.fonts.elements[element][variable]"
                @input="changeParamSetup($event , element , variable )"
                v-model="$store.state.avalon.config.fonts.elements[element][variable].setup"
                :label-text-raw="GET_FONT_PARAM_NAME(element , variable)"
                :key="element + variable + 'title'"
                param-style
                emit-setup
                @emit-setup="openEditVariable(element , variable)"


            />
          </template>

        </template>
      </div>

      <!-- Elements and variables-->
      <div v-else key="edit">

        <font-param-select
            @input="checkConfigChanged"
            v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].setup"
            param-style
        />
        <v-expand-transition>
          <div v-if="CONFIG_PARAM('fonts','elements',selectedElement ,selectedVariable ,'setup') === 'custom'">
            <!-- Family-->
            <font-family-select
                @input="checkConfigChanged(true)"
                v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].family"
            />
            <!-- Font Size -->
            <a-parameter-slider
                @input="checkConfigChanged"
                v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].size"
                style="width: 100%"
                :track-color="wsDARKLIGHT"
                :value-color="wsACCENT"
                label="FontSize"
                min="10"
                max="100"
                light
            />
            <!-- Font Size Small -->
            <a-parameter-slider
                @input="checkConfigChanged"
                v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].size_sm"
                style="width: 100%"
                :track-color="wsDARKLIGHT"
                :value-color="wsACCENT"
                label="FontSizeSmall"
                min="10"
                max="100"
                light
            />
            <!-- Weight-->
            <font-param-select
                @input="checkConfigChanged"
                v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].weight"
                :family="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].family"
            />
            <!-- Style -->
            <font-param-select
                @input="checkConfigChanged"
                v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].style"
                font-style
            />
            <!-- Spacing -->
            <a-parameter-slider
                @input="checkConfigChanged"
                v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].lineHeight"
                style="width: 100%"
                :track-color="wsDARKLIGHT"
                :value-color="wsACCENT"
                label="avalon.config.fonts.line_height"
                min="90"
                max="300"
                unit="%"
                light
            />
            <!-- Line Height -->
            <a-parameter-slider
                @input="checkConfigChanged"
                v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].spacing"
                style="width: 100%"
                :track-color="wsDARKLIGHT"
                :value-color="wsACCENT"
                label="avalon.config.fonts.letter_spacing"
                min="0"
                max="50"
                light
            />
            <!-- Case -->
            <font-param-select
                @input="checkConfigChanged"
                v-model="$store.state.avalon.config.fonts.elements[selectedElement][selectedVariable].case"
                font-case
            />
          </div>
        </v-expand-transition>



      </div>

    </v-slide-transition>



  </div>
</template>

<script>
import fontParamSelect from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontParamSelect";
import fontFamilySelect from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontFamilySelect";
import {mapState} from "vuex";

export default {
  name: "fontsSettingsHeading",
  components : {
    fontParamSelect,
    fontFamilySelect,
  },
  data() {
    return {
      selectedElement : null,
      selectedVariable : null,
    }
  },
  computed: {
    ...mapState('avalon' , ['menuNavigation']),
    fontElementsFiltered() {
      let items = this.COPY(this.DEFAULT_CONFIG.fonts.elements)

      if (this.$store.state.avalon.selectedElementType) {
        let block = this.$store.state.avalon.selectedElementType

        let checkBlockArray = [this.$store.state.avalon.selectedElementType]
        let checkBlockObject = null
        if ( this.$store.state.avalon.elementsConfig && this.$store.state.avalon.elementsConfig[block]
            && this.$store.state.avalon.elementsConfig[block].variables && this.$store.state.avalon.elementsConfig[block].variables.fonts ) {
          checkBlockArray = Object.keys(this.$store.state.avalon.elementsConfig[block].variables.fonts)
          checkBlockObject = this.$store.state.avalon.elementsConfig[block].variables.fonts
        }

        Object.keys(items).forEach(key => {
          if ( !checkBlockArray.includes(key) ) {
            delete items[key]
          }
        })

        if ( checkBlockObject ) {
          Object.keys(items).forEach(key => {
            if ( checkBlockObject[key] && checkBlockObject[key].length > 0  ) {
              Object.keys(items[key]).forEach(itemKey => {
                if ( !checkBlockObject[key].includes(itemKey) ) {
                  delete items[key][itemKey]
                }
              })
            }
          })
        }

      }

      else if (this.$store.state.avalon.selectedBlockType) {
        let block = this.$store.state.avalon.selectedBlockType

        let checkBlockArray = [this.$store.state.avalon.selectedBlockType]
        let checkBlockObject = null
        if ( this.$store.state.avalon.blocksConfig && this.$store.state.avalon.blocksConfig[block]
            && this.$store.state.avalon.blocksConfig[block].variables && this.$store.state.avalon.blocksConfig[block].variables.fonts ) {
          checkBlockArray = Object.keys(this.$store.state.avalon.blocksConfig[block].variables.fonts)
          checkBlockObject = this.$store.state.avalon.blocksConfig[block].variables.fonts
        }

        checkBlockArray = checkBlockArray.filter( (el) => {
          let data = el.split('@')
          let design = this.$store.state.avalon.selectedBlockDesign

          if ( (!design && !data[1]) || (design && !data[1]) ) {
            return true
          } else if (design && design === data[1]) {
            return true
          }
        })
        let checkBlockObjectNoDesign = {}
        let checkBlockArrayNoDesign = []
        checkBlockArray.forEach(key => {
          checkBlockArrayNoDesign.push(key.split('@')[0])
        })
        Object.keys(checkBlockObject).forEach(key => {
          checkBlockObjectNoDesign[key.split('@')[0]] = checkBlockObject[key]
        })

        Object.keys(items).forEach(key => {
          if ( !checkBlockArrayNoDesign.includes(key) ) {
            delete items[key]
          }
        })

        if ( checkBlockObjectNoDesign ) {
          Object.keys(items).forEach(key => {
            if ( checkBlockObjectNoDesign[key] && checkBlockObjectNoDesign[key].length > 0  ) {
              Object.keys(items[key]).forEach(itemKey => {
                if ( !checkBlockObjectNoDesign[key].includes(itemKey) ) {
                  delete items[key][itemKey]
                }
              })
            }
          })
        }

      }

      return items
    },
    isEditing() {
      return this.menuNavigation === 'fonts_edit_element_variable'
    }
  },
  watch : {
    menuNavigation(value) {
      if ( value === 'fonts_edit_element' ) {
        this.$store.state.avalon.menuNavigationReturn = 'fonts'
      }
      if ( value === 'fonts_edit_element_variable' ) {
        this.$store.state.avalon.menuNavigationReturn = 'fonts_edit_element'
      }
    }
  },
  methods : {
    getParamSetupValue(element , variable) {
      this.CHECK_CONFIG_PARAM('fonts','elements', element , variable)
      return this.$store.state.avalon.config.fonts.elements[element][variable].setup
    },
    changeParamSetup(value , element , variable) {
      this.$store.state.avalon.config.fonts.elements[element][variable].setup = value
      this.checkConfigChanged()
      if ( value === 'custom' ) {
        this.openEditVariable(element , variable)
      }
    },
    returnToAllVariables() {
      this.$store.state.avalon.selectedBlockType = null;
      this.$store.state.avalon.selectedElementType = null;
    },
    checkConfigChanged(checkFonts) {
      this.$store.state.avalon.configChanged = true
      if (checkFonts) {
        this.CHECK_CONFIG_FONTS()
      }
    },
    openEditVariable(element , variable ) {
      this.CHECK_CONFIG_PARAM('fonts','elements', element , variable)
      this.selectedElement = element;
      this.selectedVariable = variable ;
      this.$store.state.avalon.menuNavigation = 'fonts_edit_element_variable'
    }
  },
  beforeMount() {
    this.CHECK_CONFIG_PARAM('fonts','families')
    this.CHECK_CONFIG_PARAM('fonts','elements')

    Object.keys(this.fontElementsFiltered).forEach((element) => {
      this.CHECK_CONFIG_PARAM('fonts','elements',element)
      Object.keys((this.fontElementsFiltered[element])).forEach(param => {
        this.CHECK_CONFIG_PARAM('fonts','elements',element , param)
      })
    })

    this.$store.state.avalon.initialConfigData = this.COPY(this.SITE_CONFIG)
  },
  mounted() {

  }

}
</script>

<style scoped>

</style>