<template>
  <div>
    <h3 class="pl-6 pt-3" :style="`color : ${wsDARKER}`">{{ $t($store.state.avalon.menuOnlyStylesAndFonts ? 'StyleSettings' : 'Settings') }}</h3>
    <ws-avalon-navigation-list
        class="mt-1"
        :items="items"
        no-edit
    />

  </div>
</template>

<script>
import wsAvalonNavigationList from "@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList";
export default {
  name: "avalonMenuSettings",
  components : {
    wsAvalonNavigationList
  },
  computed : {
    items() {
      let items = [
        {
          text: this.$t('General'),
          icon: 'mdi-cog',
          id : 'settings_general',
          value : 'settings_general',
          action  : () => {
            this.$store.state.avalon.menuNavigation = 'settings_general'
          }
        },
        // {
        //   text: this.$t('DesignSettings'),
        //   icon: 'mdi-pencil-ruler',
        //   id : 'other',
        //   value : 'other',
        // },
        {
          text: this.$t('Colors'),
          icon: 'mdi-format-color-fill',
          action  : () => {
            this.$store.state.avalon.menuNavigation = 'styles'
            this.$store.state.avalon.menuNavigationReturn = 'settings'
          },
          id : 'settings',
          value : 'editor/settings',
        },
        { text: this.$t('Fonts'),
          icon: 'mdi-format-font',
          action  : () => {
            this.$store.state.avalon.menuNavigation = 'fonts'
            this.$store.state.avalon.menuNavigationReturn = 'settings'
          },
          value : 'logout'
        }
      ]
      if ( this.$store.state.avalon.menuOnlyStylesAndFonts ) {
        items.splice(0,1)
      }
      return items
    },
  },
  beforeMount() {
    this.$store.state.avalon.menuNavigationReturn = 'pages'
  }
}
</script>

<style scoped>

</style>