<template>
  <div>
    <!-- Title and Selected combination-->
    <div class="px-4">
      <h3 class="pt-3" :style="`color : ${wsDARKER}`">{{ $t('EditColorTheme') }}</h3>
      <v-sheet
          :style="`border : 1px solid ${wsDARKLIGHT} !important`"
          class="mt-2 mb- d-flex justify-space-between align-center pointer"
          style="border-radius: 8px; padding : 6px 15px"
      >
        <v-btn
            :disabled="!previousPalette"
            @click="openPreviousPalette"
            :color="wsACCENT" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h5 :style="`color : ${wsDARKER}`" > {{ $t('avalon.color_styles.' + menuNavigationStyleTheme )   }} </h5>
        <v-btn
            :disabled="!nextPalette"
            @click="openNextPalette"
            :color="wsACCENT" icon>
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-sheet>
      <!-- Selected block/element menu-->
        <div v-if="$store.state.avalon.selectedBlockType || $store.state.avalon.selectedElementType " class="pt-5 pb-2">
          <h5 @click="returnToAllVariables" class="pointer hoverUnderline" :style="`color : ${wsACCENT}`">{{ $t('DisplayAllParameters') }}</h5>
        </div>

    </div>

    <!-- Color Params-->
    <div v-for="(paramsSet, index) in paramsSets" :key="index">
      <!-- Param block title -->
      <h4 class="pt-3 px-4" :style="`color : ${wsDARKER}`">
        {{ $t(GET_COLORS_PARAMS_LOCALES(paramsSet.block)) }}
      </h4>
      <!-- Param block elements -->
      <div
          v-for="element in paramsSet.elements" :key="paramsSet + element"
          class=" pointer"
      >
        <!-- Element -->
        <v-hover #default="{hover}">
          <v-sheet
              @click="selectElement(element)"
              color="transparent"
              class="d-flex justify-space-between align-center px-4 py-2">
            <h5  :style="`color : ${wsDARKER}`">
              {{ $t(GET_COLORS_PARAMS_LOCALES(paramsSet.block , element)) }}
            </h5>
            <v-sheet
                style="border-radius: 50%"
                :style="`border : ${element === expandElement || hover ? 2 : 1}px solid ${CONFIG_PARAM_COLOR('styles',menuNavigationStyleTheme , element ) ? wsACCENT : 'red' }`"
                height="22" width="22"
                :color="CONFIG_PARAM_COLOR('styles',menuNavigationStyleTheme , element )">
              <span></span>
            </v-sheet>
          </v-sheet>
        </v-hover>

        <!-- Element color edit -->
        <v-expand-transition>
          <div
              v-if="element === expandElement"
              class="pt-3 mx-3">
            <v-sheet
                :color="wsLIGHTCARD"
                class="pa-3"
                style="border-radius: 8px"
            >
              <!-- Action title -->
              <h4 :style="`color : ${wsDARKER}`">
                {{ $t('avalon.color_styles.choose_another_color') }}
              </h4>
              <!-- Color Palette picker -->
              <v-sheet
                  class="mt-3 mb-3"
                  style="border-radius: 8px"
                  color="transparent"   >


                <div class="d-flex">
                  <v-hover
                      v-for="( color , index ) in AVALON_COLORS_LIST"
                      :key="color" #default="{hover}"
                  >
                    <v-sheet

                        @click="changeElementColor(element , color )"
                        :color="CONFIG_PARAM_COLOR('colors', color)"

                        :class="[
                        {roundedTopLeft : index === 0} ,
                        {roundedBottomLeft : index === 0} ,
                        {roundedTopRight : index === 5},
                        {roundedBottomRight : index === 5}
                      ]"
                        style="position: relative"
                        class="pointer"
                        width="100%"
                        height="32"
                    >
                      <div
                          :style="hover || $store.state.avalon.config.styles[menuNavigationStyleTheme][element] === color ? `border : 2px solid ${wsDARKLIGHT};` : null "
                          :class="[
                        {roundedTopLeft : index === 0} ,
                        {roundedBottomLeft : index === 0} ,
                        {roundedTopRight : index === 5},
                        {roundedBottomRight : index === 5}
                      ]"
                          style="width: 100%; height: 100%">
                      </div>
                    </v-sheet>
                  </v-hover>
                </div>

              </v-sheet>
              <!-- Hint -->
              <h5 @click="expandColorPicker = !expandColorPicker"  :style="`color : ${wsDARKER}`" class="ml-n2">
                <v-icon :color="wsDARKER">mdi-menu-{{ !expandColorPicker ?  'right' : 'down' }}</v-icon>
                {{ $t('ChooseColor' ) }}
              </h5>



              <v-expand-transition>
                <div      v-if="expandColorPicker">
                  <ws-text-field
                      @change="$store.state.avalon.configChanged = true; $store.state.avalon.config.styles[menuNavigationStyleTheme][element] = $event"
                      :value="GET_AVALON_COLOR($store.state.avalon.config.styles[menuNavigationStyleTheme][element])"
                      class="mt-4"
                      background-color="white"
                  />

                  <v-color-picker

                      class="mt-3"
                      @input="$store.state.avalon.configChanged = true; $store.state.avalon.config.styles[menuNavigationStyleTheme][element] = $event"
                      :value="GET_AVALON_COLOR($store.state.avalon.config.styles[menuNavigationStyleTheme][element])"
                      hide-inputs
                      hide-mode-switch
                      style="background-color: transparent"
                  />
                </div>


              </v-expand-transition>


            </v-sheet>
          </div>
        </v-expand-transition>
      </div>
      <v-divider :style="`border-color : ${wsDARKLIGHT}`"  class="mt-3"/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "avalonMenuStylesEditTheme",
  data() {
    return {
      expandColorPicker : false,
      expandElement : null,
      initialTheme : null,
    }
  },
  computed : {
    ...mapState('avalon' , ['menuNavigationStyleTheme' , 'selectedElementType' ,'selectedBlockType']),
    selectedBlockStyle() {
      return this.$store.state.avalon.selectedBlockStyle
    },
    selectedElementStyle() {
      return this.$store.state.avalon.selectedElementStyle
    },
    paramsSets() {

      let items = this.$store.state.avalon.colorsVariables.variables

      if ( this.$store.state.avalon.selectedElementType ) {

        let block = this.$store.state.avalon.selectedElementType
        let checkBlockArray = [this.$store.state.avalon.selectedElementType]
        let checkBlockObject = null
        if ( this.$store.state.avalon.elementsConfig && this.$store.state.avalon.elementsConfig[block]
            && this.$store.state.avalon.elementsConfig[block].variables && this.$store.state.avalon.elementsConfig[block].variables.colors ) {
          checkBlockArray = Object.keys(this.$store.state.avalon.elementsConfig[block].variables.colors)
          checkBlockObject = this.$store.state.avalon.elementsConfig[block].variables.colors
        }
        items = items.filter( el => checkBlockArray.includes(el.block) )
        if ( checkBlockObject ) {
          items.forEach((item,index) => {
            if ( checkBlockObject[item.block] && checkBlockObject[item.block].length > 0  ) {
              items[index].elements = checkBlockObject[item.block]
            }
          })
        }
      }
      else if ( this.$store.state.avalon.selectedBlockType ) {
        let block = this.$store.state.avalon.selectedBlockType
        let checkBlockArray = [this.$store.state.avalon.selectedBlockType]
        let checkBlockObject = null
        if ( this.$store.state.avalon.blocksConfig && this.$store.state.avalon.blocksConfig[block]
            && this.$store.state.avalon.blocksConfig[block].variables && this.$store.state.avalon.blocksConfig[block].variables.colors ) {
          checkBlockArray = Object.keys(this.$store.state.avalon.blocksConfig[block].variables.colors)
          checkBlockObject = this.$store.state.avalon.blocksConfig[block].variables.colors
        }

        checkBlockArray = checkBlockArray.filter( (el) => {
          let data = el.split('@')
          let design = this.$store.state.avalon.selectedBlockDesign

          if ( (!design && !data[1]) || (design && !data[1]) ) {
            return true
          } else if (design && design === data[1]) {
            return true
          }
        })
        let checkBlockObjectNoDesign = {}
        let checkBlockArrayNoDesign = []
        checkBlockArray.forEach(key => {
          checkBlockArrayNoDesign.push(key.split('@')[0])
        })
        if ( !checkBlockObject ) {
          checkBlockObject = {}
        }
        Object.keys(checkBlockObject).forEach(key => {
          checkBlockObjectNoDesign[key.split('@')[0]] = checkBlockObject[key]
        })

        items = items.filter( el => checkBlockArrayNoDesign.includes( el.block) )
        if ( checkBlockObjectNoDesign ) {
          items.forEach((item,index) => {
            if ( checkBlockObjectNoDesign[item.block] && checkBlockObjectNoDesign[item.block].length > 0  ) {
              items[index].elements = checkBlockObjectNoDesign[item.block]
            }
          })
        }
      }

      return items
    },
    nextPalette() {
      let index = this.AVALON_STYLES_LIST.findIndex(el => el === this.menuNavigationStyleTheme )
      if ( index  === -1 ) {
        return null
      }
      if ( index === this.AVALON_STYLES_LIST.length -1  ) {
        return null
      }

      return this.AVALON_STYLES_LIST[index+1]
    },
    previousPalette() {
      let index = this.AVALON_STYLES_LIST.findIndex(el => el === this.menuNavigationStyleTheme )
      if ( index  === -1 ) {
        return null
      }
      if ( index === 0  ) {
        return null
      }

      return this.AVALON_STYLES_LIST[index-1]
    }
  },
  watch : {
    selectedElementType() {
      this.$store.state.avalon.selectedBlockType = null
    },
    selectedBlockType() {
      this.$store.state.avalon.selectedElementType = null
    },
    selectedBlockStyle(value) {
      if ( !value ) {
        this.$store.state.avalon.menuNavigationStyleTheme = this.initialTheme
        return
      }
      this.$store.state.avalon.menuNavigationStyleTheme = value
    },
    selectedElementStyle(value) {
      if ( !value ) {
        this.$store.state.avalon.menuNavigationStyleTheme = this.initialTheme
        return
      }
      this.$store.state.avalon.menuNavigationStyleTheme = value
    }
  },
  methods : {
    returnToAllVariables() {
      this.$store.state.avalon.selectedElementType = null
      this.$store.state.avalon.selectedBlockType = null;
      this.$store.state.avalon.selectedElementType = null;
      this.$store.state.avalon.menuNavigationStyleTheme = this.initialTheme
    },
    openPreviousPalette() {
      this.$store.state.avalon.menuNavigationStyleTheme = this.previousPalette
      if ( !this.$store.state.avalon.selectedBlockType && !this.$store.state.avalon.selectedElementType ) {
        this.initialTheme = this.previousPalette
      }
    },
    openNextPalette() {
      this.$store.state.avalon.menuNavigationStyleTheme = this.nextPalette
      if ( !this.$store.state.avalon.selectedBlockType && !this.$store.state.avalon.selectedElementType ) {
        this.initialTheme = this.nextPalette
      }

    },
    changeElementColor(element , color ) {
      this.expandColorPicker = false
      this.$store.state.avalon.configChanged = true
      if ( !this.$store.state.avalon.config.styles) {
        this.$store.state.avalon.config.styles = {}
      }
      if ( !this.$store.state.avalon.config.styles[this.menuNavigationStyleTheme]) {
        this.$store.state.avalon.config.styles[this.menuNavigationStyleTheme] = {}
      }

      this.$store.state.avalon.config.styles[this.menuNavigationStyleTheme][element] = color

      this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.config)

    },
    selectElement(element) {
      if ( this.expandElement !== element ) {
        this.expandElement = element
      } else {
        this.expandElement = null
      }

    }
  },
  beforeMount() {
    this.initialTheme = this.$store.state.avalon.menuNavigationStyleTheme
    this.CHECK_CONFIG_PARAM('styles')
    this.$store.state.avalon.initialConfigData = this.COPY(this.SITE_CONFIG)
  },
}
</script>

<style scoped>
.roundedTopLeft  {
  border-top-left-radius : 6px;
}
.roundedBottomLeft  {
  border-bottom-left-radius : 6px;
}
.roundedTopRight  {
  border-top-right-radius : 6px;
}
.roundedBottomRight  {
  border-bottom-right-radius : 6px;
}
</style>