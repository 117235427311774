<template>
  <div>

    <!-- Color Pallete Editor -->
    <div class="px-6">
      <!-- Title -->
      <h3 class="pt-3 " :style="`color : ${wsDARKER}`">{{ $t('EditPalette') }}</h3>
      <!-- Colors selector-->
      <v-sheet
          class="mt-3 mb-3"
          style="border-radius: 8px"
          color="transparent"   >
        <div class="d-flex">

          <!-- Colors -->
          <v-hover
              #default="{hover}"
              v-for="( color , index ) in AVALON_COLORS_LIST" :key="color"
          >
          <v-sheet

              @click="selectedColorName = color"
              :color="CONFIG_PARAM_COLOR('colors', color)"
              :style="hover || (selectedColorName) === color ? `box-shadow : 0 0 0 2px ${wsDARKLIGHT}; margin-right: 2px` : null "
              :class="[
                {roundedTopLeft : index === 0} ,
                {roundedBottomLeft : index === 0} ,
                {roundedTopRight : index === 5},
                {roundedBottomRight : index === 5}
            ]"
              class="pointer"
              width="100%"
              height="32"
          >
          <span style="display: none">{{ color }}</span>
          </v-sheet>
          </v-hover>
        </div>

      </v-sheet>
      <!-- Color Manual Input -->
      <ws-text-field
          @input="$store.state.avalon.configChanged = true"
          v-model="$store.state.avalon.config.colors[selectedColorName]"
          class="mt-4"
          background-color="white"
      />
      <!-- Color Picker -->
      <v-color-picker
          class="mt-3"
          @input="$store.state.avalon.configChanged = true"
          v-model="$store.state.avalon.config.colors[selectedColorName]"
          hide-inputs
          hide-mode-switch
          style="background-color: transparent"
      />
    </div>

    <v-divider :style="`border-color : ${wsDARKLIGHT}`" class="mt-2 mb-1" />
    <h4 class="pt-3 px-6 " :style="`color : ${wsDARKER}`">{{ $t('ChoosePalettes') }}</h4>

    <!-- Color Pallete Selector -->
    <div class="px-6">
      <!-- Color Palletes  -->
      <v-hover
          #default="{hover}"
          v-for="(palette, index) in palettes"  :key="'palette_' + index "
      >
        <v-sheet

            @click="changePalette(palette)"
            class="mt-3 mb-3 pointer"
            style="border-radius: 6px"
            color="transparent"
            :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${wsDARKLIGHT} !important`"
        >

          <div class="d-flex">

            <v-sheet
                v-for="( color , index ) in AVALON_COLORS_LIST" :key="color"
                :color="palette[color]"
                :class="[
                {roundedTopLeft : index === 0} ,
                {roundedBottomLeft : index === 0} ,
                {roundedTopRight : index === 5},
                {roundedBottomRight : index === 5}
            ]"
                width="100%"
                height="32"
            />

          </div>

        </v-sheet>
      </v-hover>

    </div>


  </div>


</template>

<script>


import {mapActions} from "vuex";

export default {
  name: "avalonMenuStyles",
  data() {
    return {
      selectedColorName : 'background_main',
      selectedColor : '',
      initialColors : [],
      initialStyles: [],
      palettes : [],

      showSliders  : false
    }
  },
  methods : {
    ...mapActions('avalon' , ['GET_COLOR_PALETTES']),

    changePalette(palette) {
      this.$store.state.avalon.configChanged = true
      Object.keys(palette).forEach(color => {
        this.$store.state.avalon.config.colors[color] = palette[color]
      })
      this.$store.state.avalon.config.styles = this.DEFAULT_CONFIG.styles
      this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.config)
    },

    async getColorPalettes() {
      this.palettes = await this.GET_COLOR_PALETTES() || []
    }
  },
  beforeMount() {
    this.CHECK_CONFIG_PARAM('styles')
    this.$store.state.avalon.initialConfigData = this.COPY(this.SITE_CONFIG)
    this.selectedColor = this.GET_AVALON_COLOR(this.selectedColorName)
  },
  mounted() {


    this.getColorPalettes()
    setTimeout(()=> {
      let dot = document.getElementsByClassName('v-color-picker__dot')
      let controls = document.getElementsByClassName('v-color-picker__controls')

      if ( dot ) {
        dot = dot[0]
      }
      if ( controls ) {
        controls = controls[0]
      }
      controls.style.padding = '15px 0'

      dot.remove()
      this.showSliders = true
    } , 100)


  }
}
</script>

<style scoped>
.roundedTopLeft  {
  border-top-left-radius : 6px;
}
.roundedBottomLeft  {
  border-bottom-left-radius : 6px;
}
.roundedTopRight  {
  border-top-right-radius : 6px;
}
.roundedBottomRight  {
  border-bottom-right-radius : 6px;
}
</style>