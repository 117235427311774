<template>
  <div class="px-6">

    <fonts-settings-heading v-if="$store.state.avalon.menuNavigationElement === 'headings'" />
    <fonts-settings-paragraph v-if="$store.state.avalon.menuNavigationElement === 'paragraphs'" />
    <fonts-settings-other v-if="$store.state.avalon.menuNavigationElement === 'other'" />

  </div>


</template>

<script>
import fontsSettingsHeading from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontsSettingsHeading";
import fontsSettingsParagraph from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontsSettingsParagraph";
import fontsSettingsOther from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontsSettingsOther";
export default {
  name: "avalonMenuStyles",
  components : {
    fontsSettingsHeading,
    fontsSettingsParagraph,
    fontsSettingsOther,
  },
  data() {
    return {
      displayFonts : false,
      fontElements : ['headings','paragraphs' , 'buttons' , 'other']
    }
  },
  methods : {
    openFontsLibrary() {
      this.$store.state.avalon.menuNavigation = 'fonts_library'
      this.$store.state.avalon.menuNavigationReturn = 'fonts'
    },
    openEditFontElement(fontElement) {
      this.$store.state.avalon.menuNavigation = 'fonts_edit_element'
      this.$store.state.avalon.menuNavigationReturn = 'fonts'
      this.$store.state.avalon.menuNavigationStyleTheme = fontElement
    },
  },
  beforeMount() {
    this.$store.state.avalon.menuNavigationReturn = 'fonts'
  }
}
</script>

<style scoped>
.roundedTopLeft  {
  border-top-left-radius : 7px;
}
.roundedTopRight  {
  border-top-right-radius : 7px;
}
</style>