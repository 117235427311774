var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-6"},[_c('h3',{staticClass:"pt-3 ",style:(("color : " + _vm.wsDARKER))},[_vm._v(_vm._s(_vm.$t('EditPalette')))]),_c('v-sheet',{staticClass:"mt-3 mb-3",staticStyle:{"border-radius":"8px"},attrs:{"color":"transparent"}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.AVALON_COLORS_LIST),function(color,index){return _c('v-hover',{key:color,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"pointer",class:[
              {roundedTopLeft : index === 0} ,
              {roundedBottomLeft : index === 0} ,
              {roundedTopRight : index === 5},
              {roundedBottomRight : index === 5}
          ],style:(hover || (_vm.selectedColorName) === color ? ("box-shadow : 0 0 0 2px " + _vm.wsDARKLIGHT + "; margin-right: 2px") : null),attrs:{"color":_vm.CONFIG_PARAM_COLOR('colors', color),"width":"100%","height":"32"},on:{"click":function($event){_vm.selectedColorName = color}}},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(color))])])]}}],null,true)})}),1)]),_c('ws-text-field',{staticClass:"mt-4",attrs:{"background-color":"white"},on:{"input":function($event){_vm.$store.state.avalon.configChanged = true}},model:{value:(_vm.$store.state.avalon.config.colors[_vm.selectedColorName]),callback:function ($$v) {_vm.$set(_vm.$store.state.avalon.config.colors, _vm.selectedColorName, $$v)},expression:"$store.state.avalon.config.colors[selectedColorName]"}}),_c('v-color-picker',{staticClass:"mt-3",staticStyle:{"background-color":"transparent"},attrs:{"hide-inputs":"","hide-mode-switch":""},on:{"input":function($event){_vm.$store.state.avalon.configChanged = true}},model:{value:(_vm.$store.state.avalon.config.colors[_vm.selectedColorName]),callback:function ($$v) {_vm.$set(_vm.$store.state.avalon.config.colors, _vm.selectedColorName, $$v)},expression:"$store.state.avalon.config.colors[selectedColorName]"}})],1),_c('v-divider',{staticClass:"mt-2 mb-1",style:(("border-color : " + _vm.wsDARKLIGHT))}),_c('h4',{staticClass:"pt-3 px-6 ",style:(("color : " + _vm.wsDARKER))},[_vm._v(_vm._s(_vm.$t('ChoosePalettes')))]),_c('div',{staticClass:"px-6"},_vm._l((_vm.palettes),function(palette,index){return _c('v-hover',{key:'palette_' + index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-sheet',{staticClass:"mt-3 mb-3 pointer",staticStyle:{"border-radius":"6px"},style:(("box-shadow : 0 0 0 " + (hover ? 2 : 1) + "px " + _vm.wsDARKLIGHT + " !important")),attrs:{"color":"transparent"},on:{"click":function($event){return _vm.changePalette(palette)}}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.AVALON_COLORS_LIST),function(color,index){return _c('v-sheet',{key:color,class:[
              {roundedTopLeft : index === 0} ,
              {roundedBottomLeft : index === 0} ,
              {roundedTopRight : index === 5},
              {roundedBottomRight : index === 5}
          ],attrs:{"color":palette[color],"width":"100%","height":"32"}})}),1)])]}}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }