<template>
  <div class="px-6">
    <h3 class="pt-3" :style="`color : ${wsDARKER}`">{{ $t('ColorStyles') }}</h3>

    <v-sheet
        :style="`border : 1px solid ${wsDARKLIGHT} !important`"
        class="mt-3 mb-3"
        style="border-radius: 8px"
        color="transparent"   >

      <div class="d-flex">

        <v-sheet
            v-for="(color , index ) in AVALON_COLORS_LIST" :key="color"
            :class="[{roundedTopLeft : index === 0} , {roundedTopRight : index === 5}]"
            width="100%" height="90" :color="GET_AVALON_COLOR(color)" />
      </div>


      <v-divider :style="`border-color : ${wsDARKLIGHT}; height : 1px`" />

      <v-btn
          @click="openEditPalette"
          block text :color="wsACCENT" class="noCaps"> {{ $t('EditPalette') }}</v-btn>
    </v-sheet>

    <h4 class="pt-3" :style="`color : ${wsDARKER}`">{{ $t('ColorCombinations') }}</h4>

    <v-hover
        v-for="(style) in  AVALON_STYLES_LIST" :key="style"
        #default="{hover}">
      <v-sheet
          @click="openEditTheme(style)"
          style="border-radius: 4px !important; padding : 6px 15px"
          :style="`border : ${hover ? 2 : 1}px solid  ${wsDARKLIGHT} !important`"
          class="mt-2 d-flex justify-space-between align-center pointer"
          height="48"

          :color="CONFIG_PARAM_COLOR( 'styles', style , 'bg' )"
      >
        <h3 :style="`color : ${CONFIG_PARAM_COLOR( 'styles', style , 'h1' )}`">Ab</h3>
        <h5 :style="`color : ${CONFIG_PARAM_COLOR( 'styles', style , 'p' )}`" > {{ $t('avalon.color_styles.' + style )   }} </h5>

        <div style="width: 20px">
          <v-icon :color="CONFIG_PARAM_COLOR( 'styles', style , 'h1' ) || wsACCENT" size="20" v-if="hover">
            mdi-pencil
          </v-icon>
        </div>
      </v-sheet>
    </v-hover>


  </div>


</template>

<script>
export default {
  name: "avalonMenuStyles",
  methods : {
    openEditPalette() {
      this.$store.state.avalon.menuNavigation = 'styles_palette'
      this.$store.state.avalon.menuNavigationReturn = 'styles'
    },
    openEditTheme(style) {
      this.$store.state.avalon.menuNavigation = 'styles_theme'
      this.$store.state.avalon.menuNavigationReturn = 'styles'
      this.$store.state.avalon.menuNavigationStyleTheme = style
    },
  },
  beforeMount() {
    this.$store.state.avalon.menuNavigationReturn = 'settings'
  }
}
</script>

<style scoped>
.roundedTopLeft  {
  border-top-left-radius : 7px;
}
.roundedTopRight  {
  border-top-right-radius : 7px;
}
</style>