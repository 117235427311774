<template>
  <div>
    <div
        v-if="!$store.state.avalon.pageSearch"
        @click="$store.commit('avalonPage/OPEN_NEW_NEWS')"
        style="padding-left : 27px"
    >
      <div class="py-2 d-flex align-center  pointer">
        <v-icon style="margin-right: 15px" :color="wsACCENT">mdi-plus-circle</v-icon>
        <h5 :style="`color : ${wsACCENT}`">{{ $t('AddNews') }}</h5>
      </div>
    </div>

    <ws-avalon-navigation-list
        @edit-action="editAction"
        v-model="$store.state.avalon.selectedNews"
        :items="items"
        mandatory
        custom-action
    />

    <ws-dialog
        v-if="displayDelete"
        v-model="displayDelete"
        :title="$t('DeleteNews')"
        close-delete
        display-confirm-delete
        @delete="deleteNews"
    >

    </ws-dialog>

  </div>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "avalonMenuNews",
  components : {
    wsAvalonNavigationList : () => import("@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList")
  },
  data() {
    return {
      news : [],
      selectedNewsId : null,
      displayDelete : false
    }
  },
  computed : {
    items() {
      let items = []
      if ( !this.$store.state.avalon.newsSelect ) {
        return []
      }

      this.$store.state.avalon.newsSelect.forEach(item => {
        items.push({
          text : item.title,
          path: this.businessPublicLink(`news/${item.alias}/editor`),
          value : item.alias,
          uuid : item.uuid,
          display_draft : !(item.published || false),
          img : item.img,
          image_preview : true,
          date : item.published ? item.date : null,
          is_published : item.published,
          edit : false
        })
      })

      return items
    }
  },
  methods : {
    ...mapActions('news', [
        'GET_ADMIN_BUSINESS_NEWS_ALL',
        'ADD_EDIT_BUSINESS_NEWS',
        'DELETE_BUSINESS_NEWS'
    ]),


    async publishNews(id) {
      let data = {
        uuid : id,
        published : true,
        lang : this.selectedLang
      }
      await this.saveNews(data)
      this.notify(this.$t('NewsPublished') , 'success')
    },
    async unPublishNews(id) {
      let data = {
        uuid : id,
        published : false,
        lang : this.selectedLang
      }
      await this.saveNews(data)
      this.notify(this.$t('NewsUnPublished'))
    },

    async saveNews(data) {
      data.lang = this.$store.state.avalon.selectedLang

      let result = await this.ADD_EDIT_BUSINESS_NEWS(data)
      if (!result) { return }
      let index = this.$store.state.avalon.newsSelect.findIndex(el => el.uuid === result.uuid )
      if ( index === -1 ) {
        return
      }
      this.$store.state.avalon.newsSelect[index] = result
      this.$store.state.avalon.newsSelect = this.COPY(this.$store.state.avalon.newsSelect)
    },

    async deleteNews() {
      let result = await this.DELETE_BUSINESS_NEWS(this.selectedNewsId)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.$store.state.avalon.newsSelect.findIndex(el => el.uuid === this.selectedNewsId )
      if ( index === -1 ) {
        return
      }
      this.$store.state.avalon.newsSelect.splice(index , 1)
      this.notify( this.$t('NewsDeleted') )
      this.displayDelete = false
    },

    async editAction({action, item}) {

      if ( action === 'edit' ) {
        this.$store.commit('avalonPage/OPEN_EDIT_NEWS' , item.value)
      }

      if ( action === 'copy' ) {
        this.$store.commit('avalonPage/OPEN_COPY_NEWS' , item.value)
      }
      if ( action === 'delete' ) {
        this.selectedNewsId = item.uuid
        this.displayDelete = true
      }
      if ( action === 'publish' ) {
        this.publishNews(item.uuid)
      }
      if ( action === 'unpublish' ) {
        this.unPublishNews(item.uuid)
      }
      if ( action === 'link' ) {
        try {
          let text = this.businessPublicLink('news/' + item.value , true )
          await navigator.clipboard.writeText(text);
          this.notify(this.$t('CopiedToClipboard') )
        } catch($e) {
          this.notify(this.$t('Error') )
        }
      }
    },


  },
  async mounted(){
    let result = await this.GET_ADMIN_BUSINESS_NEWS_ALL()
    if ( !result ) {
      return
    }
    if ( !result.news ) {
      return
    }
    this.$store.state.avalon.newsSelect = result.news
  }
}
</script>

<style scoped>

</style>